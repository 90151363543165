import { Link } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";
import React from "react";

const CollectionsListing = ({ collections = [] }) => {
  return (
    <div className="relative max-w-[1140px] w-full mx-auto box-border show fadeIn my-5 sm:my-10 px-5 lg:px-10">
      <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3">
        {collections.map((collection) => (
          <Link
            to={`/collections/${collection.store.slug.current}`}
            className="w-full px-[10px] mb-5"
            key={collection.store.slug.current}
          >
            {/* Image */}
            <div
              className="mb-2 aspect-video rounded-md"
              style={{
                boxShadow: "rgba(0, 0, 0, 0.2) 0px 0px 10px",
              }}
            >
              <img
                src={collection.store.imageUrl}
                alt={collection.store.title}
                objectFit="contain"
                className="w-full h-full object-contain"
                loading="eager"
              />
            </div>
            <div className="">
              <h2 className="text-lg pt-2 lg:text-xl text-[#666666] font-bold uppercase  mb-1">
                {collection.store.title}
              </h2>
              {/* <h3 className="text-base font-semibold tracking-[1px] text-[#666666]">
                {collection.store.productsCount} Products
              </h3> */}
            </div>
          </Link>
        ))}
      </div>
    </div>
  );
};

export default CollectionsListing;
